
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import StringSelectBox, { StringSelectBoxOptions } from '@/cloudfun/components/SelectStringBox.vue'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import ProductFileUpload from "@/components/file/ProductFileUpload.vue";
import { formatAmount } from '@/libs/utils'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    StringSelectBox,
    ProductFileUpload,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("ProductCreate");
    const canUpdate = checkPermissions("ProductUpdate");
    const canDelete = checkPermissions("ProductDelete");

    const gridOptions: GridOptions = {
      stripe: false,
      title: '產品管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      expand: true,
      printConfig: { sheetName: '產品管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '產品管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        {
          field: 'ProductNo',
          title: '品號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          slots: { default: 'link', }
        },
        {
          field: 'SKU',
          title: 'SKU',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'ProductName',
          title: '品名',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'ProductDescription',
          title: '產品說明',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: 'ProductSize',
          title: '產品尺寸',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('product/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('product/query') : undefined,
        save: model ? (params) => model.dispatch('product/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true, fullscreen: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'ProductNo',
          title: '品號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'SKU',
          title: 'SKU',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'ProductType',
        //   title: '產品說明(產品)',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'AdhesiveType',
        //   title: '產品說明(膠別)',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'ColorName',
        //   title: '產品說明(顏色)',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'ProductName',
          title: '品名',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'EnglishProductName',
          title: '英文品名',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'ProductDescription',
        //   title: '產品說明',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'EnglishProductDescription',
        //   title: '英文產品說明',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'Package',
          title: '包裝方式',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'EnglishPackage',
          title: '英文包裝方式',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'PackagingDescription',
        //   title: '包裝說明',
        //   span: 12,
        //   slots: { default: "column-PackagingDescription" }
        // },
        // {
        //   field: 'EnglishPackagingDescription',
        //   title: '英文包裝說明',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'TitlePaper',
          title: '標頭紙',
          span: 12,
          //itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
          itemRender: { name: '$select', props: { clearable: true }, options: [
                    { value: '7mm白色塑膜 翹頭', label: '7mm白色塑膜 翹頭' },
                    { value: '18mm白色塑膜 翹頭', label: '18mm白色塑膜 翹頭' },
                    { value: '白底藍箭頭 翹頭', label: '白底藍箭頭 翹頭' },
                    { value: '白底紅箭頭 翹頭', label: '白底紅箭頭 翹頭' },
                    { value: '白底黑箭頭 翹頭', label: '白底黑箭頭 翹頭' },
                    { value: '7mm白色塑膜 不翹頭', label: '7mm白色塑膜 不翹頭' },
                    { value: '15mm白色塑模，不翹頭', label: '15mm白色塑模，不翹頭' },
                    { value: '18mm白色塑膜 不翹頭', label: '18mm白色塑膜 不翹頭' },
                    { value: '白底藍箭頭 不翹頭', label: '白底藍箭頭 不翹頭' },
                    { value: '白底紅箭頭 不翹頭', label: '白底紅箭頭 不翹頭' },
                    { value: '白底黑箭頭 不翹頭', label: '白底黑箭頭 不翹頭' },
                    { value: '外圈OPP印刷膜 (白底藍箭頭+黑字)', label: '外圈OPP印刷膜 (白底藍箭頭+黑字)' },
                    { value: '外圈OPP印刷膜 (白底紅箭頭+黑字)', label: '外圈OPP印刷膜 (白底紅箭頭+黑字)' },
                    { value: '綠底白箭頭(PMS 7482) 翹頭', label: '綠底白箭頭(PMS 7482) 翹頭' },
                    { value: '紅底白箭頭(PMS 485) 翹頭', label: '紅底白箭頭(PMS 485) 翹頭' },
                    { value: '紫底白箭頭(PMS 2597) 翹頭', label: '紫底白箭頭(PMS 2597) 翹頭' },
                    { value: '外圈OPP印刷膜 (白底黑箭頭+黑字)', label: '外圈OPP印刷膜 (白底黑箭頭+黑字)' },
                    { value: '白底綠箭頭(PMS 7482) 翹頭', label: '白底綠箭頭(PMS 7482) 翹頭' },
                    { value: '白底紅箭頭(PMS 485) 翹頭', label: '白底紅箭頭(PMS 485) 翹頭' },
                    { value: '白底紫箭頭(PMS 2597) 翹頭', label: '白底紫箭頭(PMS 2597) 翹頭' },
                    { value: '無', label: '無' },
                ]}
        }, 
        {
          field: 'PaperTube',
          title: '管芯',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'Long',
        //   title: '長',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入數字', clearable: true, type: 'float' } }
        // },
        // {
        //   field: 'Width',
        //   title: '寬',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入數字', clearable: true, type: 'float' } }
        // },
        // {
        //   field: 'Thickness',
        //   title: '厚度',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入數字', clearable: true, type: 'float' } }
        // },
        {
          field: 'ProductSize',
          title: '產品尺寸',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Uint',
          title: '單位',
          span: 12,
          //itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
          itemRender: { name: '$select', props: { clearable: true }, options: [
                    { value: '卷', label: '卷' },
                    { value: '套', label: '套' },
                    { value: '箱', label: '箱' },
                ]}
        },
        {
          field: 'Color',
          title: '顏色',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },            
        // {
        //   field: 'PackagingDescription',
        //   title: '包裝說明',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },        
        {
          field: 'Radix',
          title: '套/箱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入數字', clearable: false, type: 'number' } }
        },
        {
          field: 'Bundle',
          title: '卷/套(束)',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入數字', clearable: false, type: 'number' } }
        },
        {
          field: 'PalletsRadix',
          title: '箱/板',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入數字', clearable: false, type: 'number' } }
        },
        {
          field: 'GW',
          title: '@G.W',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入數字', clearable: false, type: 'float' } }
        },
        {
          field: 'NW',
          title: '@N.W',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入數字', clearable: false, type: 'float' } }
        },
        {
          field: 'CBM',
          title: 'CBM (箱)',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入數字', clearable: false, type: 'float', digits: 4 } }
        },
        {
          field: 'SQM',
          title: 'SQM (套)',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入數字', clearable: false, type: 'float', digits: 2 } }
        },
        {
          field: 'Cuft',
          title: 'Cuft',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入數字', clearable: false, type: 'float' } }
        },
        {
          field: 'UnitPrice',
          title: '單位價格',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入數字', clearable: false, type: 'float', digits: 3 } }
        },
        {
          field: 'CustomerId',
          title: '客戶',
          span: 12,
          slots: { default: "column-CustomerId" }
        },
        // {
        //   field: 'ShippingMark',
        //   title: 'Shipping Mark',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } }
        },
        {
          span: 24,
          slots: { default: 'file' }
        },     
      ],
      rules: {
        CustomerId: [{ required: true }],
        ProductName: [{ type: 'string', required: true }],
        Bundle: [{ required: true }],
        PalletsRadix: [{ required: true }],
        GW: [{ required: true }],
        NW: [{ required: true }],
        CBM: [{ required: true }],
        SQM: [{ required: true }],
        Cuft: [{ required: true }],
        UnitPrice: [{ required: true }],
        Radix: [{ required: true }],
      }
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Number",
      valueField: "Id",
      columns: [
        {
          field: "Number",
          title: "客戶代號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },        
      ],
      showHeader: true,
      promises: {
        find: async (value: any) => await model!.dispatch("customer/find", value), // eslint-disable-line
        query: async (params: any) => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return await model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    const initData = (row: any, callback: any) => {
      // row.Long = 0;
      // row.Width = 0;
      // row.Thickness = 0;
      row.Radix = 0;
      row.UnitPrice = 0;
      row.GW = 0;
      row.NW = 0;
      row.CBM = 0;
      row.SQM = 0;
      row.Bundle = 1;
      row.PalletsRadix = 0;
      row.Cuft = 0;
      callback();
    }

    const unique = (data: any, name: string) => {
      let result = [
        ...new Set(data.map((e: any) => e[name])),
      ];
      return result;
    };

    const packagingDescriptionSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "包裝說明",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: async (value: any) => await model!.dispatch("product/find", value), // eslint-disable-line
        query: async (params: any) => {
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          var result = await model!.dispatch("product/query", params);
          result.data = result.data.map((e:any) => { return { Name: e.PackagingDescription, Id: e.PackagingDescription, ProductName: e.ProductName } });
          if(result.data.length == 0) {
            result.data = [{ Name: params.keyword, Id: params.keyword, ProductName: '' }]
          }
          return result; // eslint-disable-line
        }
      },      
    };

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition("ProductId", Operator.Equal, fileModal.selectedRow.Id.toString());
            const sortings = [{ column: "CreatedTime", order: 0 }];
            return await model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Product_' + fileModal.selectedRow.Id, fileType: 0 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const linkVXEModal = ref<any>();
    const linkModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show(data: any) {
        linkModal.visible = true;
        linkModal.selectedRow = data;
        linkVXEModal.value.maximize()
      },
    })

    const productClone = async (row: any) => {      
      if(row.Id) {
        try {
          await model?.dispatch('product/clone', row.Id);
          CloudFun.send("info", {
            subject: "執行成功",
            content: `已複製產品`,
          });
          grid.value.isModalPopup = false;
          grid.value.editingRow = null;
          grid.value.refresh();
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      selectorOptions,
      initData,
      canCreate,
      packagingDescriptionSelectorOptions,
      fileGrid,
      fileModal,
      linkVXEModal,
      linkModal,
      formatAmount,
      productClone,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async onGridEdit (row: any, callback: any) {
      const entity = row.Id ? await this.$model.dispatch('product/find', row.Id) : undefined
      this.fileModal.show(row)
      Object.assign(row, entity)
      callback()
    },
  }
})
